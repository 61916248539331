// # Stripe Subscription concept
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import * as api from 'services/api';

// # Action types
const FETCH_STRIPE_INVOICE = 'stripeInvoice/FETCH_STRIPE_INVOICE';
const FETCH_STRIPE_INVOICE_SUCCESS = 'stripeInvoice/FETCH_STRIPE_INVOICE_SUCCESS';
const FETCH_STRIPE_INVOICE_FAIL = 'stripeInvoice/FETCH_STRIPE_INVOICE_FAIL';

export interface StripeInvoiceLineItem {
  id: number;
  amount: number;
  currency: string;
  description: string;
}

export interface StripeInvoice {
  amount_due: number;
  items: StripeInvoiceLineItem[];
  currency: string;
}

interface FetchStripeInvoiceAction {
  type: typeof FETCH_STRIPE_INVOICE;
}

interface FetchStripeInvoiceSuccessAction {
  type: typeof FETCH_STRIPE_INVOICE_SUCCESS;
  payload: StripeInvoice;
}

interface FetchStripeInvoiceFailAction {
  type: typeof FETCH_STRIPE_INVOICE_FAIL;
}

type StripeInvoiceActionTypes =
  | FetchStripeInvoiceAction
  | FetchStripeInvoiceSuccessAction
  | FetchStripeInvoiceFailAction;

// # Selectors
export const getStripeUpcomingInvoice = (state: RootState) => state.stripeUpcomingInvoice.invoice as StripeInvoice;
export const getStripeInvoiceRequestStatus = (state: RootState) =>
  state.stripeUpcomingInvoice.invoiceRequestStatus;

export const getHasLoadedStripeUpcomingInvoice = createSelector(
  getStripeInvoiceRequestStatus,
  (status: RequestStatusType) => status !== 'initial' && status !== 'loading'
);

// # Actions
export const fetchStripeUpcomingInvoice = (siteId: number) => (dispatch: any) => {
  dispatch({ type: FETCH_STRIPE_INVOICE });
  return api
    .fetchStripeUpcomingInvoice(siteId)
    .then((response) => dispatch({ type: FETCH_STRIPE_INVOICE_SUCCESS, payload: response.data }))
    .catch((error) => dispatch({ type: FETCH_STRIPE_INVOICE_FAIL, payload: error?.response?.data }));
};

// # Reducer
export interface StripeInvoiceState {

  invoice?: StripeInvoice | null;
  invoiceRequestStatus: RequestStatusType;
}

export const initialState: StripeInvoiceState = {
  invoice: undefined,
  invoiceRequestStatus: 'initial',
};

export default function reducer(state = initialState, action: StripeInvoiceActionTypes): StripeInvoiceState {
  switch (action.type) {
    case FETCH_STRIPE_INVOICE: {
      return {
        ...state,
        invoiceRequestStatus: 'loading',
      };
    }

    case FETCH_STRIPE_INVOICE_SUCCESS: {
      return {
        ...state,
        invoiceRequestStatus: 'success',
        invoice: action.payload,
      };
    }

    case FETCH_STRIPE_INVOICE_FAIL: {
      return {
        ...state,
        invoiceRequestStatus: 'failure',
      };
    }

    default: {
      return state;
    }
  }
}
